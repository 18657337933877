import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() text: string = '';
  @Input() content: any = null;
  @Input() buttons: Array<any> | null = null;
  innerHtml: SafeHtml | null = null;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.innerHtml = this.domSanitizer.bypassSecurityTrustHtml(this.content);
    if (!this.buttons) {
      this.buttons = [
        {
          class: 'raw-button',
          value: null,
          label: 'Cancel',
        },
        {
          class: 'custom-button',
          value: { success: true },
          label: 'Confirm',
        },
      ];
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  close(status?: any) {
    this.activeModal.close(status);
  }
}
