<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
  <button type="button" class="raw-button" aria-label="Close" (click)="dismiss()">
    <div class="fa fa-times"></div>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center">
      <div [innerHtml]="innerHtml"></div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button *ngFor="let btn of buttons" type="button" class="{{btn.class}}"
          (click)="close(btn.value)">{{btn.label}}</button>
</div>
